._2AvnI[data-placement^='bottom'] ._34rNM,
._2AvnI[data-placement^='top'] ._34rNM,
._3UnCH,
._1S11l,
._35mSQ {
  margin-left: -8px;
  position: absolute;
}

._2AvnI[data-placement^='bottom'] ._34rNM,
._2AvnI[data-placement^='top'] ._34rNM,
._3UnCH,
._1S11l,
._35mSQ,
._2AvnI[data-placement^='bottom']
  ._34rNM::before,
._2AvnI[data-placement^='top']
  ._34rNM::before,
._3UnCH::before,
._1S11l::before,
._35mSQ::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

._2AvnI[data-placement^='bottom']
  ._34rNM::before,
._2AvnI[data-placement^='top']
  ._34rNM::before,
._3UnCH::before,
._1S11l::before,
._35mSQ::before {
  content: '';
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

._2AvnI[data-placement^='bottom'] ._34rNM {
  top: 0;
  margin-top: -8px;
}

._2AvnI[data-placement^='bottom'] ._34rNM,
._2AvnI[data-placement^='bottom']
  ._34rNM::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

._2AvnI[data-placement^='bottom']
  ._34rNM::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

._2AvnI[data-placement^='top'] ._34rNM,
._3UnCH,
._1S11l,
._35mSQ {
  bottom: 0;
  margin-bottom: -8px;
}

._2AvnI[data-placement^='top'] ._34rNM,
._3UnCH,
._1S11l,
._35mSQ,
._2AvnI[data-placement^='top']
  ._34rNM::before,
._3UnCH::before,
._1S11l::before,
._35mSQ::before {
  border-bottom: none;
  border-top-color: #fff;
}

._2AvnI[data-placement^='top']
  ._34rNM::before,
._3UnCH::before,
._1S11l::before,
._35mSQ::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

._3JKpo {
  display: inline-block;
  padding: 0;
  border: 0;
  width: 100%;
}

._1R92F {
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.7rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #999999;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

._3moh- ._34rNM {
  left: 35px;
}

._3moh- ._BtJXh {
  border-left: 0;
}

._3moh- ._3-kyA {
  border-radius: 0.3rem;
}

._3moh- ._1uPdf {
  border-radius: 0.3rem;
}

._34rNM {
  position: absolute;
  left: 50px;
}

._2AvnI {
  z-index: 999999;
}

._2AvnI[data-placement^='bottom'] {
  margin-top: 10px;
}

._2AvnI[data-placement='bottom-end']
  ._34rNM,
._2AvnI[data-placement='top-end'] ._34rNM {
  left: auto;
  right: 50px;
}

._2AvnI[data-placement^='top'] {
  margin-bottom: 10px;
}

._2AvnI[data-placement^='right'] {
  margin-left: 8px;
}

._2AvnI[data-placement^='right'] ._34rNM {
  left: auto;
  right: 42px;
}

._2AvnI[data-placement^='left'] {
  margin-right: 8px;
}

._2AvnI[data-placement^='left'] ._34rNM {
  left: 42px;
  right: auto;
}

._ao8My {
  background-color: #ffffff;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 12px;
  position: relative;
}

._1O9dh {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

._3fRqU,
._DNTRK,
._370XB,
._1c3FB,
._1sLKt,
._2QAVj {
  display: inline-block;
  margin: 0 0.9rem;
}

._3JF6s,
._3xbD8,
._10yHz {
  margin: 0 0.9rem;
  color: #000;
  font-weight: bold;
  font-size: 0.9rem;
  text-transform: uppercase;
}

._3xbD8 {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

._B-fPR {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 8px;
  z-index: 1000000;
  height: 22px;
  width: 22px;
}

._B-fPR:focus {
  outline: 2px solid black;
}

._NrydF::before {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  display: inline-block;
  height: 0.6em;
  position: relative;
  top: 0.4em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.6em;
}

._NrydF._buyy3:before {
  left: 0;
  transform: rotate(45deg);
}

._NrydF._3J_n2:before {
  left: 0.15em;
  transform: rotate(-135deg);
}

._3VpFh {
  background-size: contain;
  border: none;
  right: 40px;
  border-right-color: #ccc;
}

._3VpFh:hover {
  border-right-color: #b3b3b3;
}

._3mEdV,
._3mEdV:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

._2vyk7 {
  background-size: contain;
  border: none;
  right: 16px;
  border-left-color: #ccc;
}

._vsDqA:not(._2G6JK) {
  right: 80px;
}

._2vyk7:hover {
  border-left-color: #b3b3b3;
}

._3TIl9,
._3TIl9:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

._3RLNX {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

._1Whjh {
  top: 4px;
  border-top-color: #ccc;
}

._1Whjh:hover {
  border-top-color: #b3b3b3;
}

._26Ono {
  top: -4px;
  border-bottom-color: #ccc;
}

._26Ono:hover {
  border-bottom-color: #b3b3b3;
}

._1Mb6k {
  /* float: left; */
  border-bottom: 1px solid #999999;
}

._slzrB:focus{
  color:white;
  background-color: #2965cc;
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}


._3WzLV:focus{
  color:white;
  background-color: #2965cc;
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}


._3ng7z {
  /* margin: 0.4rem; */
  display: flex;
  flex-wrap: wrap;
  overflow: hidden;
  border-bottom: 1px solid #999999;
}

._3WzLV {
  display: inline-block;
  cursor: pointer;
  text-align: center;
  flex: 1 0 20%;
  width: 50px;
  margin: 6px;
  padding: 2px 0;
}

._FsbYZ {
  border-radius: 0.5rem;
  background-color: #2965cc;
  color: #fff;
}

._3WzLV._FsbYZ:hover {
  background-color: #487bd3;
}

._3W2jI {
  margin: 0.4rem 0;
}

._3W2jI ._slzrB,
._3W2jI ._2FPax {
  display: inline-block;
  width: 2.5rem;
  margin: 5px 10px;
  padding: 1px;
  text-align: center;
}

._29mV_ {
  width: 250px;
  height: 150px;
  margin: 1rem 0.4rem;
  text-transform: uppercase;
}

._1aXqs {
  text-align: center;
}

._1NOkY {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

._1NOkY ._3Fzd4 {
  display: inline-block;
}

._1NOkY
  ._21J2T {
  display: inline-block;
}

._1NOkY
  ._21J2T
  ._vfV9t {
  display: inline-block;
  margin-left: 10px;
}

._1NOkY
  ._21J2T
  ._vfV9t
  input {
  width: 85px;
}

._1NOkY
  ._21J2T
  ._vfV9t
  input[type='time']::-webkit-inner-spin-button,
._1NOkY
  ._21J2T
  ._vfV9t
  input[type='time']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

._1NOkY
  ._21J2T
  ._vfV9t
  input[type='time'] {
  -moz-appearance: textfield;
}

._1NOkY
  ._21J2T
  ._362WQ {
  margin-left: 5px;
  display: inline-block;
}

._BtJXh {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 85px;
}

._140Bp {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

._BtJXh ._3-kyA {
  position: relative;
  background: white;
}

._BtJXh
  ._3-kyA
  ._1uPdf {
  width: 85px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9 {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9
  li._3mHMc {
  height: 30px;
  padding: 5px 10px;
  white-space: nowrap;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9
  li._3mHMc:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9
  li._eu_aq {
  background-color: #2965cc;
  color: white;
  font-weight: bold;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9
  li._eu_aq:hover {
  background-color: #487bd3;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9
  li._3kFXT {
  color: #ccc;
}

._BtJXh
  ._3-kyA
  ._1uPdf
  ul._1Kzf9
  li._3kFXT:hover {
  cursor: default;
  background-color: transparent;
}

._3flsZ {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

._3flsZ._17eqD {
  cursor: pointer;
}

._3flsZ._17eqD:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

._1DwdE,
._3omt8 {
  white-space: nowrap;
}

._1DwdE {
  border-bottom: 1px solid #e2e2e2;
}

._1KcXW,
._sJqnt,
._QT5ZN {
  color: #767676;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.066rem 0.366rem;
}

._1KcXW {
  color: #767676;
  font-weight: bold;
}

._zMVGL,
._3cfIJ,
._5FpzF,
._1c2lK,
._3EutH,
._3sTbF {
  border-radius: 0.3rem;
  background-color: #2965cc;
  color: #fff;
}

._zMVGL:hover,
._3cfIJ:hover,
._5FpzF:hover,
._1c2lK:hover,
._3EutH:hover,
._3sTbF:hover {
  background-color: #487bd3;
}

._29Z1-,
._1cUgY {
  color: #ccc;
  pointer-events: none;
}

._29Z1-:hover,
._1cUgY:hover {
  cursor: default;
  background-color: transparent;
}

._sJqnt,
._slzrB,
._2FPax,
._3WzLV {
  border-radius: 1rem;
  cursor: pointer;
  outline: 0;
}

._sJqnt:hover,
._slzrB:hover,
._2FPax:hover,
._3WzLV:hover {
  background-color: #f0f0f0;
  box-shadow: none;
  color: #000;
}

._2sBRZ,
._3HZqs,
._BEU2y {
  font-weight: bold;
}

._1Wr27,
._2wdCA,
._1frKa {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

._1Wr27:hover,
._2wdCA:hover,
._1frKa:hover {
  background-color: #32be3f;
}

._3E1rJ,
._Uctf4,
._1IuPs {
  color: magenta;
}

._1AJqH,
._2heNG,
._2haVj {
  color: green;
}

._3trse,
._2W6Mt,
._1upcn,
._1uq0z,
._38zPW,
._1tmMN,
._37tcK,
._1OMJ2,
._2eoSV {
  border-radius: 1rem;
  background-color: #2965cc;
  color: #fff;
}

._3trse:hover,
._2W6Mt:hover,
._1upcn:hover,
._1uq0z:hover,
._38zPW:hover,
._1tmMN:hover,
._37tcK:hover,
._1OMJ2:hover,
._2eoSV:hover {
  background-color: #487bd3;
}

._3trse:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}

._1uARm,
._3U9qc,
._31O_M {
  border-radius: 1rem;
  background-color: #2965cc;
  color: #fff;
}

._1uARm:focus,
._3U9qc:focus,
._31O_M:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(21, 156, 228, 0.4);
}

._1uARm:hover,
._3U9qc:hover,
._31O_M:hover {
  background-color: #487bd3;
}

._2W6Mt,
._38zPW,
._1OMJ2 {
  background-color: rgba(33, 107, 165, 0.5);
}

._2q8ti ._1upcn,
._2q8ti
  ._1tmMN,
._2q8ti
  ._2eoSV {
  background-color: #f0f0f0;
  color: #000;
}

._L1G7U,
._3GEuz,
._1-_SZ {
  cursor: default;
  color: #ccc;
}

._L1G7U:hover,
._3GEuz:hover,
._1-_SZ:hover {
  background-color: transparent;
}

._slzrB._zMVGL:hover,
._slzrB._5FpzF:hover,
._slzrB._1c2lK:hover,
._slzrB._3sTbF:hover,
._2FPax._zMVGL:hover,
._2FPax._5FpzF:hover,
._2FPax._1c2lK:hover,
._2FPax._3sTbF:hover {
  background-color: #487bd3;
}

._slzrB:hover,
._2FPax:hover {
  background-color: #f0f0f0;
}

._2ArkC {
  position: relative;
  display: inline-block;
  width: 100%;
}

._24axh,
._3aOeA,
._2zWge {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

._3WzVX {
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
}

._24axh:hover,
._3aOeA:hover,
._2zWge:hover {
  cursor: pointer;
}

._24axh:hover
  ._3UnCH,
._24axh:hover
  ._1S11l,
._3aOeA:hover
  ._3UnCH,
._3aOeA:hover
  ._1S11l,
._2zWge:hover
  ._3UnCH,
._2zWge:hover
  ._1S11l {
  border-top-color: #b3b3b3;
}

._3UnCH,
._1S11l,
._35mSQ {
  border-top-color: #454545;
  float: right;
  margin-left: 8px;
  top: 5px;
  position: relative;
  border-width: 0.3rem;
}

._3OYoB,
._2jLvY,
._2z_Jr {
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 5px solid black;
  float: right;
  margin-left: 8px;
  top: 5px;
  position: relative;
}

._2or-P,
._fQ10U,
._ixSL6 {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 999999;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

._2or-P:hover,
._fQ10U:hover,
._ixSL6:hover {
  cursor: pointer;
}

._372gI,
._1t2s3,
._2QRPL {
  height: 150px;
  overflow-y: scroll;
}

._10KPz,
._3KPOR,
._2T-Vh {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

._10KPz:first-of-type,
._3KPOR:first-of-type,
._2T-Vh:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

._10KPz:last-of-type,
._3KPOR:last-of-type,
._2T-Vh:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

._10KPz:hover,
._3KPOR:hover,
._2T-Vh:hover {
  background-color: #ccc;
}

._10KPz:hover
  ._26Ono,
._3KPOR:hover
  ._26Ono,
._2T-Vh:hover
  ._26Ono {
  border-bottom-color: #b3b3b3;
}

._10KPz:hover
  ._1Whjh,
._3KPOR:hover
  ._1Whjh,
._2T-Vh:hover
  ._1Whjh {
  border-top-color: #b3b3b3;
}

._1L5_I,
._RIbLt,
._3_e7D {
  position: absolute;
  left: 15px;
}

._3BJjt {
  visibility: hidden;
}

._2LlQM {
  cursor: pointer;
  background-color: transparent;
  border: 0;
  outline: 0;
  padding: 0px 6px 0px 0px;
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  display: table-cell;
  vertical-align: middle;
}

._2LlQM::after {
  cursor: pointer;
  background-color: #216ba5;
  color: #fff;
  border-radius: 50%;
  height: 16px;
  width: 16px;
  padding: 2px;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  display: table-cell;
  vertical-align: middle;
  content: '\D7';
}

._3fD29 {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

._3dcFZ {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

._3dcFZ ._1KcXW,
._3dcFZ ._sJqnt,
._3dcFZ ._QT5ZN {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  ._3dcFZ ._1KcXW,
  ._3dcFZ ._sJqnt,
  ._3dcFZ ._QT5ZN {
    width: 2rem;
    line-height: 2rem;
  }
}

._3dcFZ ._3JF6s,
._3dcFZ ._3xbD8 {
  font-size: 1.44rem;
}

._3dcFZ ._B-fPR {
  border: 0.81rem solid transparent;
}

._3dcFZ ._3VpFh {
  border-right-color: #ccc;
}

._3dcFZ ._3VpFh:hover {
  border-right-color: #b3b3b3;
}

._3dcFZ ._3mEdV,
._3dcFZ
  ._3mEdV:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

._3dcFZ ._2vyk7 {
  border-left-color: #ccc;
}

._3dcFZ ._2vyk7:hover {
  border-left-color: #b3b3b3;
}

._3dcFZ ._3TIl9,
._3dcFZ ._3TIl9:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

/**
* Rangeslider
*/
._2FLBE {
    margin: 100px 0;
    position: relative;
    background-color: rgba(226, 226, 226, 1);
    border: 1px solid rgba(179, 179, 179, 1);
    touch-action: none;
    flex-grow: 1;
}

._x6d3v {
    display: flex;
}

._2A-Le {
    display: flex;
    pointer-events: none;
}

._215tJ {
    display: contents;
}

._2sxzg {
    display: contents;
    pointer-events: none;
}

._2PO62 {
    align-self: center;
    width: 70px;
    color: #3aa7cc;
    font-weight: bold;
}


._2FLBE,
._2FLBE ._3G368 {
    display: block;
    cursor: pointer;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.4);
}
._2FLBE ._2AcBi {
    border: 1px solid #ccc;
    cursor: pointer;
    display: inline-block;
    position: absolute;
}
._2FLBE ._2AcBi ._1-4YM {
    opacity: 1;
}
._2FLBE ._367BJ {
    text-align: center;
    position: absolute;
    transition: all 100ms ease-in;
    border-radius: 4px;
    display: inline-block;
    color: white;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    font-weight: 500;
    color: rgba(41, 101, 204, 1);
    font-size: 18px;
    margin-top: -14px;
}
._2FLBE ._367BJ span {
    display: inline-block;
    line-height: 100%;
    font-size :16px;
}

/**
* Rangeslider - Horizontal slider
*/
._3AD_r {
    height: 8px;
    border-radius: 4px;
}
._3AD_r ._3G368 {
    height: 100%;
    border-radius: 10px;
    top: 0;
    cursor: pointer;
}
._3AD_r ._2AcBi {
    width: 24px;
    height: 24px;
    border-radius: 24px;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}

._3AD_r ._367BJ {
    top: -35px;
}
._3AD_r ._367BJ:after {
    border-left: 8px solid transparent;
    border-right: 8px solid transparent;
    border-top: 8px solid rgba(0, 0, 0, 0.8);
    left: 50%;
    bottom: -8px;
    transform: translate3d(-50%, 0, 0);
}
/**
* Rangeslider - Vertical slider
*/
._2OV_R {
    margin: 20px auto;
    height: 150px;
    max-width: 10px;
    background-color: transparent;
}
._2OV_R ._3G368,
._2OV_R ._2AcBi {
    position: absolute;
}
._2OV_R ._3G368 {
    width: 100%;
    background-color: #7cb342;
    box-shadow: none;
    bottom: 0;
}
._2OV_R ._2AcBi {
    width: 30px;
    height: 10px;
    left: -10px;
    box-shadow: none;
}
._2OV_R ._367BJ {
    left: -100%;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
}
._2OV_R ._367BJ:after {
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 8px solid rgba(0, 0, 0, 0.8);
    left: 100%;
    top: 12px;
}
/**
* Rangeslider - Reverse
*/
._3d02n._3AD_r ._3G368 {
    right: 0;
}
._3d02n._2OV_R ._3G368 {
    top: 0;
    bottom: inherit;
}
/**
* Rangeslider - Labels
*/
._2H9VG {
    position: relative;
}
._2OV_R ._2H9VG {
    position: relative;
    list-style-type: none;
    margin: 0 0 0 24px;
    padding: 0;
    text-align: left;
    width: 250px;
    height: 100%;
    left: 10px;
}
._2OV_R ._2H9VG ._j0g80 {
    position: absolute;
    transform: translate3d(0, -50%, 0);
}
._2OV_R ._2H9VG ._j0g80::before {
    content: '';
    width: 10px;
    height: 2px;
    background: black;
    position: absolute;
    left: -14px;
    top: 50%;
    transform: translateY(-50%);
    z-index: -1;
}
._2H9VG ._j0g80 {
    position: absolute;
    cursor: pointer;
    display: inline-block;
    top: 10px;
    transform: translate3d(-50%, 0, 0);
    color: rgba(118, 118, 118, 1);
    font-size: 16px;
}

._2AcBi:focus {
    outline: none;
}